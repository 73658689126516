<template lang="pug">
  FormModal(title="Edit Text", method="PUT", :endpoint="`blocks/${block_id}`", :data="data", v-bind="$attrs", @success="onSuccess", class="edit-text-block-modal")
    component(:is="tabsComponent")
      component(:is="tabComponent", title="Text")
        MarkdownInput(v-model="data.synopsis", label="Content", name="synopsis")
        InputGroup(v-if="!collection_id", name="collection_ids", label="Collection(s) - optional", description="Click the chevron or your down arrow key to see list")
          MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="options/all/collections", option-label="label")

        h2.section-title Visibility
        PublishAtInput(v-model="data.published_at")
        ExpiresAtInput(v-model="data.expires_at")

      Tab(v-if="collection_id", title="Inspires")
        p The following are only applied to the image when it's used within Inspires collections.
        InputGroup.mt-8(label="Customisable - Optional", name="inspires_customisable")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_customisable")
            span.ml-4 Allow advisors to customise this block's content
        //- InputGroup(label="Placeholder - Optional", name="inspires_placeholder")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_placeholder")
            span.ml-4 Only display this block if the advisor customises it
</template>

<script>
import MarkdownInput from "@/components/Form/MarkdownInput.vue"
import PublishAtInput from "@/components/Form/PublishAtInput.vue"
import ExpiresAtInput from "@/components/Form/ExpiresAtInput.vue"
import { Tabs, Tab } from "@syntax51/app-kit"
import { mapGetters } from "vuex"

export default {
  components: {
    MarkdownInput,
    PublishAtInput,
    ExpiresAtInput,
    Tabs,
    Tab
  },
  props: {
    block_id: {
      type: Number,
      required: true
    },
    collection_id: {
      type: Number
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      synopsis: "",
      inspires_customisable: null,
      inspires_placeholder: null,
      expires_at: null,
      published_at: null
    },
    tabsComponent: null,
    tabComponent: null
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  async created() {
    this.data.collection_id = this.collection_id
    this.tabsComponent = this.user.partner.inspires_customisations_enabled && this.collection_id ? Tabs : "div"
    this.tabComponent = this.user.partner.inspires_customisations_enabled && this.collection_id ? Tab : "div"

    let params = this.collection_id ? `?collection_id=${this.collection_id}` : ""
    let response = await this.$api.get(`blocks/${this.block_id}${params}`)

    this.data = Object.assign(this.data, {
      synopsis: response.data.data.synopsis,
      inspires_customisable: response.data.data.inspires_customisable,
      inspires_placeholder: response.data.data.inspires_placeholder,
      collection_ids: response.data.data.collection_ids,
      expires_at: response.data.data.expires_at,
      published_at: response.data.data.published_at
    })
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Text successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.edit-text-block-modal {
  .tab {
    @apply pt-8 md:pt-12;
  }

  .section-title {
    @apply mb-6 border-b-2 pb-3 text-base;
    @apply md:mb-8;
  }

  * + .section-title {
    @apply mt-6;
    @apply md:mt-8;
    @apply lg:mt-12;
  }
}
</style>
